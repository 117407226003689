<template>
<transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
  <div v-if="showOverlay === 'downloadPicker'" class="w-full h-auto md:h-full fixed bottom-0 md:inset-0 z-30 flex flex-row justify-center items-center pointer-events-none text-new-black font-arial">
    <div class="w-full md:w-auto md:min-w-1/3 h-auto md:h-auto bg-new-yellow rounded-t-3xl md:rounded-3xl p-5 pb-10 md:p-12 pointer-events-auto">
      <div class="md:hidden text-xl cursor-pointer" @click="hidePicker">schließen</div>
      <div class="mt-2 md:mt-0 font-faro font-bold text-3xl">Downloads</div>

      <div v-if="downloads" class="w-full bg-white rounded-xl shadow-new mt-5 border border-hi-lighgrey overflow-hidden">
        <div v-for="(download, index) in downloads" :key=download>
          <div class="w-full p-3 flex flex-row justify-between items-center font-arial cursor-pointer" :class="{'border-b border-new-yellow' : index < downloads.length -1, 'bg-new-black' : index === clickedIndex}">
            <a v-if="index !== clickedIndex" :href="formatUrl(download.Datei.data.attributes.url)" :target="mobile? '_self': '_blank'" class="flex-grow pr-2 flex flex-row justify-between items-center no-underline text-new-black" download @click="startDownload(index)">{{download.Bezeichnung}}</a>
            <div v-else class="text-white flex flex-row justify-between items-center">
              <loadingSVG />
              <div class="ml-3">Der Download startet in Kürze...</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</transition>
</template>

<script>
// SVG
import loadingSVG from '../../../assets/svg/loading.vue';

// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';
import mobile from '../../../mixins/mobile.vue';

export default {
  name: 'downloadPicker',
  components: { loadingSVG },
  mixins: [slideInOutBottom, iosVibrations, mobile],

  data() {
    return {
      clickedIndex: null,
    };
  },

  computed: {
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },
    downloads() {
      return this.$store.state.learning.selectedLernpfad?.lernpfade?.data?.attributes?.download;
    },
  },

  watch: {
    showOverlay() {
      if (!this.showOverlay) {
        this.clickedIndex = null;
      }
    },
  },

  mounted() {
    window.downloadCompleted = () => {
      this.downloadCompleted();
    };
  },

  methods: {

    downloadCompleted() {
      this.clickedIndex = null;
    },

    formatUrl(url) {
      if (url) {
        if (url.startsWith('https://')) return url;
        const newUrl = `https://${url}`;
        return newUrl;
      }
    },

    startDownload(index) {
      this.iosVibrate(0);
      if (this.mobile) {
        this.clickedIndex = index;
      }
    },
    hidePicker() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', null);
    },
  },
};
</script>
